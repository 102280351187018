@font-face {
  font-family: 'Myriad Pro';
  src: url(../fonts/MYRIADPRO-REGULAR.OTF) format('truetype');
}

body {
  background: #292929;
}

.landing-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.landing-page > .background {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landing-page > .brightness-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.landing-page > .background > img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in;
}

.landing-page > .logoC {
  position: fixed;
  top: 4.63vh;
  left: 3.13vw;
  width: 195px;
}

.landing-page > .explore-label {
  position: fixed;
  top: 25.83vh;
  left: 16.35vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 200%;
  text-align: left;
  color: #ffffff;
  margin: 0;
  opacity: 0.9;
}

.landing-page > .explore-label > svg {
  width: 55px;
  height: 2px;
  margin-right: 21px;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.landing-page > .explore-label > svg > * {
  stroke: #ffffff;
  stroke-width: 2;
}

.landing-page > .about-label {
  position: fixed;
  top: 25.83vh;
  left: 40.89vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 200%;
  text-align: left;
  color: #ffffff;
  margin: 0;
  opacity: 0.9;
}

.landing-page > .about-label > svg {
  width: 55px;
  height: 2px;
  margin-right: 21px;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.landing-page > .about-label > svg > * {
  stroke: #ffffff;
  stroke-width: 2;
}

.landing-page > .desc {
  position: fixed;
  top: 34vh;
  left: 44.9vw;
  width: 28.28vw;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.005em;
  text-align: left;
  color: #ffffff;
  margin: 0;
  white-space: pre-wrap;
}

.landing-page > .btn-cont {
  position: fixed;
  top: 33.06vh;
  left: 16.35vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.landing-page > .btn-cont > .start {
  /*width: 22.55vw;*/
  padding-bottom: 6.67vh;
  margin: 0;
  background-color: transparent;
  border: none;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 100%;
  color: #ffffff;
  cursor: pointer;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition: opacity 0.3s ease-in;
}

.landing-page > .btn-cont > .start > svg {
  width: 55px;
  height: 2px;
  margin-right: 21px;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.landing-page > .btn-cont > .start:hover > svg {
  opacity: 1;
}

.landing-page > .btn-cont > .start > svg > * {
  stroke: #ffffff;
  stroke-width: 2;
}

.landing-page > .poweredBy {
  width: 165px;
  height: auto;
  position: fixed;
  top: 87.31vh;
  left: 83.23vw;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height */

  text-transform: lowercase;
}

.landing-page > img.client-logo {
  width: 140px;
  height: auto;
  position: fixed;
  bottom: 4.63vh;
  right: 3.13vw;
  cursor: pointer;
}

.landing-page > .poweredBy > svg {
  margin: 9px 0 0 0;
}

.landing-page > .day-night-label {
  position: fixed;
  top: 72.96vh;
  left: 16.35vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 200%;
  text-align: left;
  color: #ffffff;
  margin: 0;
  opacity: 0.9;
}

.landing-page > .day-night-label > svg {
  width: 55px;
  height: 2px;
  margin-right: 21px;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.landing-page > .day-night-label > svg > * {
  stroke: #ffffff;
  stroke-width: 2;
}

.landing-page > .day-night-container {
  position: fixed;
  top: 78.1vh;
  left: 20.1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.landing-page > .day-night-container > .day-night-content {
  width: 68px;
  height: 68px;
  margin-right: 1.43vw;
  cursor: pointer;
}

.landing-page > .day-night-container > .day-night-content > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.8));
}

.landing-page > .day-night-container > .day-night-content > svg > * {
  fill: #ffffff;
}

@media screen and (max-width: 1366px) {
  .landing-page > .explore-label {
    position: fixed;
    top: 25.83vh;
    left: 16.35vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    font-family: 'Mukta Vaani', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 200%;
    text-align: left;
    color: #ffffff;
    margin: 0;
    opacity: 0.9;
  }

  .landing-page > .about-label {
    position: fixed;
    top: 25.83vh;
    left: 40.89vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    font-family: 'Mukta Vaani', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 200%;
    text-align: left;
    color: #ffffff;
    margin: 0;
    opacity: 0.9;
  }

  .landing-page > .desc {
    position: fixed;
    top: 34vh;
    left: 44.9vw;
    width: 28.28vw;
    font-family: 'Mukta Vaani', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.005em;
    text-align: left;
    color: #ffffff;
    margin: 0;
    white-space: pre-wrap;
  }

  .landing-page > .btn-cont > .start {
    /*width: 22.55vw;*/
    padding-bottom: 6.67vh;
    margin: 0;
    background-color: transparent;
    border: none;
    font-family: 'Mukta Vaani', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 100%;
    color: #ffffff;
    cursor: pointer;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: opacity 0.3s ease-in;
  }

  .landing-page > .day-night-label {
    position: fixed;
    top: 72.96vh;
    left: 16.35vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    font-family: 'Mukta Vaani', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 200%;
    text-align: left;
    color: #ffffff;
    margin: 0;
    opacity: 0.9;
  }

  .landing-page > .day-night-container > .day-night-content {
    width: 58px;
    height: 58px;
    margin-right: 1.43vw;
    cursor: pointer;
  }

  .landing-page > .day-night-container > .day-night-content > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.8));
  }
}

.stop-sharing-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border: 5px solid #92de47;
  pointer-events: none;
}

.remote-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border: 5px solid #d92626;
  pointer-events: all;
}

.stop-sharing-indicator > .ss-container {
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stop-sharing-indicator > .ss-container > .ss-white {
  background: #ffffff;
  opacity: 0.85;
  border-radius: 5px 0 0 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #000004;
  padding: 8px 16px;
}

.stop-sharing-indicator > .ss-container > .ss-red {
  background: #d92626;
  opacity: 0.85;
  border-radius: 0 5px 5px 0;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #ffffff;
  padding: 2px 16px 2px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
}

.limit-reached {
  position: fixed;
  width: 100%;
  height: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/*.block-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.6);
  z-index: 101;
}*/

.block-container {
  position: fixed;
  bottom: 17vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 4.96681px;
  z-index: 1;
  padding: 16px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #000004;
}

.lobby-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: rgba(50, 50, 50, 0.9);
  pointer-events: all;
}
